import React from "react"
import {graphql, Link} from "gatsby"
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
// import Image from "../components/image"
// import { Helmet } from "react-helmet"

import Layout from "../components/layout"

import Seo from "../components/seo"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBasketballBall} from "@fortawesome/free-solid-svg-icons";
import {faClock, faFolderOpen} from "@fortawesome/free-regular-svg-icons";

import "../components/nleague.css"

const pageNleague = ({ data, location, pageContext }) => (
   <Layout>
        <Seo
            pagetitle={`N.LEAGUE 3x3(エヌリーグ スリーエックススリー)`}
            pagedesc={`「N.LEAGUE 3x3(エヌリーグ スリーエックススリー)」は、オリンピック競技に選ばれた3人制バスケ「3x3(スリーエックススリー)」の熊本での普及と発展を願って活動中のワンデイリーグです。ZEROSTEPは情報配信をと通して、N.LEAGUEの活動支援しています。`}
            pagepath={location.pathname}
            pageimg={data.nleague.childImageSharp.original.src}
            pageimgw={data.nleague.childImageSharp.original.width}
            pageimgh={data.nleague.childImageSharp.original.height}
        />
        <section className="hero hero--nl">
            <StaticImage src="../images/nleague-main-01.jpg"  alt="N.LEAGUE TOP" style={{width:"100%"}} />
            <div className="catch">
                <div className="nl-logo">
                    <StaticImage src="../images/nleague-logo.png" alt="N.LEAGUE 3x3" style={{width:"100%"}} />
                </div>
                <h1>N.LEAGUE3x3</h1>
                <p><span>オリンピック競技に選ばれた3人制バスケ3x3の</span><span>熊本での普及と発展を願って活動中！</span></p>
            </div>
        </section>

       <section className="u-pt-3">
           <div className="container">
               <h2>最近の記事</h2>
               <div className="posts">
                   {data.allContentfulBlogPost.edges.map(({ node }) => (
                       <article className="post post-1_2" key={node.id}>
                           <Link to={`/post/${node.slug}`}>
                               <figure>
                                   <GatsbyImage
                                       image={node.eyecatch.gatsbyImageData}
                                       alt={node.eyecatch.description}
                                       style={{height:"100%"}} />
                               </figure>
                               <aside className="info post-item_info">
                                   <time dateTime={ node.publishDate }>
                                       <FontAwesomeIcon icon={faClock} />
                                       { node.publishDateJP }
                                   </time>

                                   <div className="cat">
                                       <FontAwesomeIcon icon={faFolderOpen} />
                                       <ul>
                                           {node.category.map(cat => (
                                               <li className={cat.categorySlug} key={cat.id}>{cat.category}</li>
                                           ))}
                                       </ul>
                                   </div>
                               </aside>
                               <h3>{ node.title }</h3>
                           </Link>
                       </article>
                   ))}
               </div>
           </div>
       </section>

       <div className="container">
            <hr/>
       </div>

       <section className="u-pt-3">
           <div className="container">
               <h2>試合結果</h2>
               <div className="posts">
                   {data.allContentfulGameResult.edges.map(({ node }) => (
                       <article className="post post-1_3" key={node.id}>
                           <Link to={`/result/${node.slug}`}>
                               <figure>
                                   <GatsbyImage
                                       image={node.eyecatch.gatsbyImageData}
                                       alt={node.eyecatch.description}
                                       style={{height:"100%"}} />
                               </figure>
                               <aside className="info post-item_info">
                                   <time dateTime={ node.publishDate }>
                                       <FontAwesomeIcon icon={faClock} />
                                       { node.publishDateJP }
                                   </time>

                                   <div className="cat">
                                       <FontAwesomeIcon icon={faBasketballBall} />
                                       <ul>
                                           {node.category.map(cat => (
                                               <li className={cat.categorySlug} key={cat.id}>{cat.category}</li>
                                           ))}
                                       </ul>
                                   </div>
                               </aside>
                               <h3>{ node.title }</h3>
                           </Link>
                       </article>
                   ))}
               </div>
           </div>
       </section>

       <section className="photo">
           <h2 className="sr-only">Photo</h2>
           <StaticImage src="../images/bottom_bg--basketball.jpg" alt="バスケットボール" style={{width:"100%"}} />
       </section>

   </Layout>
)
export default pageNleague;

export const query = graphql`{
  nleague: file(relativePath: {eq: "thumb.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
      original {
        src
        height
        width
      }
    }
  }
  allContentfulBlogPost(
    sort: {order: DESC, fields: publishDate}
    skip: 0
    limit: 4
    filter: {category: {elemMatch: {categorySlug: {eq: "n-league"}}}}
  ) {
    edges {
      node {
        title
        publishDateJP: publishDate(formatString: "YYYY年MM月DD日")
        publishDate
        category {
          category
          categorySlug
          id
        }
        id
        slug
         eyecatch{
          description
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            width: 500
            layout: FULL_WIDTH
          )
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
        }
      }
    }
  }
  allContentfulGameResult(
    sort: {order: DESC, fields: publishDate}
    skip: 0
    limit: 3
    filter: {category: {elemMatch: {categorySlug: {eq: "n-league3x3"}}}}
  ) {
    edges {
      node {
        id
        title
        publishDateJP: publishDate(formatString: "YYYY年MM月DD日")
        publishDate
        category {
          category
          categorySlug
          id
        }
        id
        slug
        eyecatch{
          description
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            width: 500
            layout: FULL_WIDTH
          )
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
        }
      }
    }
  }
}
`